import { useState,useEffect } from 'react'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'

//Components
import ImagesCarousel from './images-carousel'
import CustomPopup from '../elements/custom-popup'
import TooltipIcon from '../elements/tooltip-icon'
import FormattedDate from '../elements/formatted-date'
import DeleteButton from '../elements/button/delete-button'
import CancelButton from '../elements/button/secondary-action-button'
import FavoriteButton from '../elements/button/favorite-button'

//constant
import {AMENITIES_PARKING,AMENITIES_POOL} from '../../../constant/amenities'

//Images
import RenovatedImage from '../../../images/renovated.png'
import FloorImage from '../../../images/floor plan.png'
import LocationImage from '../../../images/location.png'
import RadiusImage from '../../../images/location_radius.png'
import PetAllowedImage from '../../../images/pets_friendly.png'
import PoolImage from '../../../images/swimmingpool.png'
import ParkingImage from '../../../images/parking.png'
import NoProperty from '../../../images/no_property_photo.png'
import { getPrimaryPropertyImage } from '../../../utils/files'
import EyeIcon from '../../../images/admin-icons/eye-icon.svg'
import TrashIcon from '../../../images/admin-icons/trash-icon-green.svg'
//styles
import '../styles/property-card-actions.css'

const PropertyCardActions = ({data,size,onDelete}) => {
  const [visibleDelete, setVisibleDelete] = useState(false)
  
  const [floorsDescription,setFloorsDescription] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [backImageUrl, setBackImageUrl] = useState('')
  // const [formattedImage,setFormattedImage] = useState('')
  const [amenitiesFound,setAmenitiesFound] = useState({
    pool: false,
    parking: false
  })
  const onHidingDelete = () => {
    setVisibleDelete(false)
  }
  const onConfirmDelete = id => {
    onDelete(id)
    onHidingDelete()
  }
  // const [dateRenovationFormatted, setDateRenovationFormatted] = useState('')
  useEffect(() => {
    let floors = []
    data.floorPlans.forEach(floor => floors.push(floor.name))
    if(floors.length > 0)
      setFloorsDescription(floors.join(','))
    else
      setFloorsDescription('All Floor Plans')

    let foundPool = data?.amenities?.some(amenity => AMENITIES_POOL.includes(amenity.id ))
    let foundParking = data?.amenities?.some(amenity => AMENITIES_PARKING.includes(amenity.id ))
    setAmenitiesFound({
      pool: foundPool,
      parking: foundParking
    })
    //setFormattedImage(data.imageUrl && `${baseUrl}${data.imageUrl}`)
    // setDateRenovationFormatted(`${data.dateOfRenovation && new Date(data.dateOfRenovation.toString()).toLocaleString('default', { month: 'short' })} ${data.dateOfRenovation && data.dateOfRenovation.split('T')[0].split('-')[0]}`)
    if(data.propertyImage && data.propertyImage.imageId) {
      setImageUrl(`/property/images/${data.propertyImage.imageId}.${data.propertyImage.contentType}`);
        setBackImageUrl(getPrimaryPropertyImage(data.id));
    } else {
      setImageUrl(NoProperty);
      setBackImageUrl(NoProperty);
    }
  }, [data])

    return (
        <div className={`custom-card-actions ${size === 'fullsize' ? 'custom-card-actions--fullsize' : ''}`}>
            <div className={`custom-card__image ${data.isMuted ? 'custom-card-actions--muted' : ''}`}
            style={{
                backgroundImage: `url(${backImageUrl})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                textIndent: '-10000px'
                }}
            >
            {/* <ImagesCarousel images={data.imageUrl} height={90} width={135}/> */}
                <img src={imageUrl}
                  height={90} width={135}
                  />
          </div>
          <div className={`custom-card__title ${data.isMuted ? 'custom-card-actions--muted' : ''}`}><span>{`Property #${data.metisId}`}</span><span>{data.recentlyRenovated && <TooltipIcon id={`renovated_${data.id}`} image={RenovatedImage} label={<FormattedDate fdate={data.dateOfRenovation}/>}/>}</span></div>
          <div className={`custom-card__description ${data.isMuted ? 'custom-card-actions--muted' : ''}`}>
                <div><img src={LocationImage} alt='location img' width='17px' height='17px' />{data.formattedAddress.replace(/\s+/g,'').replace(/,{2,}/g,',')}</div>
                {data.distanceInMiles > 0 &&
                    <div><img src={RadiusImage} alt='radius img' width='17px' height='17px' />{data.distanceInMiles} Miles.</div>
                }
              <div><img src={FloorImage} alt='floor img' width='17px' height='17px'/>{floorsDescription}</div>              
          </div>
          <div className={`custom-card__includes ${data.isMuted ? 'custom-card-actions--muted' : ''}`}>
            {(amenitiesFound.pool || amenitiesFound.parking || data.petsAllowed) &&
              <div className='custom-card__includes__amenities'>
                {amenitiesFound.pool && <TooltipIcon id={`pool_${data.id}`} image={PoolImage} label={'Swimming Pool'}/>}
                {amenitiesFound.parking && <TooltipIcon id={`parking_${data.id}`} image={ParkingImage} label={'Parking'}/>}
                {data.petsAllowed && <TooltipIcon id={`petAllowed_${data.id}`} image={PetAllowedImage} label={'Pets Friendly'}/>}
              </div>
            }
              <div className='custom-card__includes__building-type'>{data.buildingTypeName}</div>
          </div>
          <div className='custom-card__unavailable'>
            {data.notMatch && data.notMatch.length > 0 && 
              <div className='custom-card__no-match'>
                <div className='custom-card__no-match__title'>No Match</div>
                {
                  data.notMatch.map((item,index) => 
                    <div key={index} className='custom-card__no-match__list-item'>
                      {item}
                    </div> 
                  )
                }
              </div>
            }
          </div>
          <div className={`custom-card__actions`}>
            <Link target='_blank' to={`/property/dashboard/${data.metisId}`}><img src={EyeIcon}/> <span className='custom-card__actions__text'>View details</span></Link>
            <span style={{cursor: 'pointer'}} onClick={_ =>setVisibleDelete(true)}><img src={TrashIcon}/><span className='custom-card__actions__text'>Delete</span></span>
            {/* <FavoriteButton key={data.id} propertyId={data.id} isLiked={data.isLiked} likeAProperty={likeAProperty} unLikeAProperty={unLikeAProperty}/> */}
          </div>
          <CustomPopup visible={visibleDelete} onHiding={onHidingDelete}>
                <div className='custom-card__actions__modal'>
                   <div className='custom-card__actions__modal__title'>
                        DELETE PROPERTY
                   </div>
                   <div className='custom-card__actions__modal__info'>
                        Are you sure you want to delete the Property #{data.metisId}
                   </div>
                   <div className='custom-card__actions__modal__buttons'>
                        <CancelButton action={onHidingDelete}>Cancel</CancelButton>
                        <DeleteButton action={_=>onConfirmDelete(data.id)}>Delete</DeleteButton>
                   </div>
                </div>
          </CustomPopup>
      </div> 
  )
}

export default PropertyCardActions